import React from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import { Button, ListItem, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  title: string;
}

const Item = styled.div`
  display: block;
  padding-top: 0;
  padding-bottom: 0;
`;

const ItemLeaf = styled.div`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
`;

const Button = styled.button`
  padding: 10px 8px;
  justify-content: flex-start;
  text-transform: none;
  letter-spacing: 0;
  width: 100%;
`;

const ButtonLeaf = styled(Button)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};

  &.depth-0 {
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

const ActiveButton = styled(ButtonLeaf)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  href,
  title,
  ...rest
}) => {
  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <Item
        className={clsx(className)}
        {...rest}
      >
        <Button style={style}>{title}</Button>
        {children}
      </Item>
    );
  }

  return (
    <ItemLeaf
      className={clsx(className)}
      {...rest}
    >
      <ActiveButton
        as={RouterLink}
        activeClassName="active"
        className={clsx('depth', `depth-${depth}`)}
        exact
        style={style}
        to={href}
      >
        {title}
      </ActiveButton>
    </ItemLeaf>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default NavItem;
