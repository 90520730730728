import { openDB } from 'idb';

const dbPromise = openDB('auth-db', 1, {
  upgrade(db) {
    db.createObjectStore('auth', {
      keyPath: 'key'
    });
  }
});

export const setToken = async (token) => {
  const db = await dbPromise;
  await db.put('auth', { key: 'accessToken', value: token });
};

export const getToken = async () => {
  const db = await dbPromise;
  const result = await db.get('auth', 'accessToken');
  return result ? result.value : null;
};

export const removeToken = async () => {
  const db = await dbPromise;
  await db.delete('auth', 'accessToken');
};
