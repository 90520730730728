import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getConexion, postConexion } from 'src/contexts/Conexion';
import objFromAccountsDonated from 'src/utils/objFromAccountsDonated';

const initialState = {
  isLoaded: false,
  accountList: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'donatedAccounts',
  initialState,
  reducers: {
    getDonatedAccounts(state, action) {
      const board = action.payload;
      state.accountList.byId = objFromAccountsDonated(board);
      state.accountList.allIds = Object.keys(state.accountList.byId).map(Number);
      state.isLoaded = true;
    },
    createAccount(state, action) {
      const account = action.payload;
      state.accountList.byId[account.donated_accounts_id] = account;
      state.accountList.allIds.push(account.donated_accounts_id);
    },
    updateAccount(state, action) {
      const account = action.payload;
      state.accountList.byId[account.donated_accounts_id] = account;
    }
  }
});

export const { reducer } = slice;

export const getDonatedAccounts = () => async (dispatch) => {
  const response = await getConexion("users/getDonatedAccounts");
  dispatch(slice.actions.getDonatedAccounts(response));
};

export const createAccount = (objDonated: any) => async (dispatch) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const currentDate = `${mm}/${dd}/${yyyy} ${time}`;
  const objUser = {
    createDate: currentDate,
    codeDonation: objDonated.code,
    description: objDonated.description,
    statusAccount: false,
    userId: "",
    expired: false
  };
  const { Message } = await postConexion("users/createDonatedAcc", objUser);
  dispatch(slice.actions.createAccount(Message.value));
};

export const updateAccount = (update: any) => async (dispatch) => {
  const objResponse = { ...update, expired: !update.expired };
  const objResponseApi = {
    codeDonation: update.codeDonation,
    expired: !update.expired
  };
  await postConexion("users/updateDonatedAcc", objResponseApi);
  dispatch(slice.actions.updateAccount(objResponse));
};

export const initAccount = (codeDonation: string, userId: string) => async () => {
  const objResponseApi = {
    codeDonation,
    userId
  };
  const resp = await postConexion("users/updateDonatedAccOff", objResponseApi);
  if (resp.Code === "ERROR") throw new Error(resp.Message.value);
};

export default slice;
