import { useState, useEffect, forwardRef } from 'react';
import type { FC } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import { getConexionOut } from 'src/contexts/Conexion';
import { Link as RouterLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import useStyles from './styleHome';

const HowWorkView: FC<any> = forwardRef(({ refIn }) => {
  const classes = useStyles();
  const [categorizedDocs, setCategorizedDocs] = useState<any>([]);

  useEffect(() => {
    getCategorizedDocuments();
  }, []);

  return (
    <Page className={classes.root} title="Cómo funciona">
      <>
        <div className={classes.boxMainContentHow} ref={refIn}>
          <Box
            className={classes.titleSomos}
            mb={3}
            sx={{
              fontFamily: 'Poppins !important'
            }}
          >
            PORTAFOLIO DE DOCUMENTOS
          </Box>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <span
                className={classes.subTitleSomos1}
                style={{
                  fontFamily: 'Poppins'
                }}
              >
                Generar tu propio documento nunca fue
              </span>{' '}
              <span
                className={classes.subTitleSomos2}
                style={{ fontFamily: 'Poppins !important' }}
              >
                tan fácil
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className={classes.subTitleSomos3}
              style={{
                fontFamily: 'Poppins'
              }}
            />
            <Grid
              item
              xs={12}
              sm={4}
              className={classes.subTitleSomos3}
              style={{
                fontFamily: 'Poppins'
              }}
            >
              Documentos constantemente actualizados y revisados
              por nuestro equipo de abogados.
            </Grid>
          </Grid>

          <Box mb={3}>
            <Box mb={3}>&nbsp;</Box>
          </Box>

          <Grid container spacing={8} alignItems="center" justifyContent="center">
            {categorizedDocs.map((doc, key) => (
              <Grid key={key} item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <div className={classes.flipCard} style={{ maxWidth: '450px', width: '95%' }}>
                    <div className={classes.flipCardInner}>
                      <div className={classes.flipCardFront}>
                        <Box className={classes.contentFrontCard}>
                          {doc.name}
                        </Box>
                      </div>
                      <div className={classes.flipCardBack}>
                        <p className={classes.nameContrato}>{doc.name}</p>
                        <p className={classes.descContrato}>
                          {doc.description}
                        </p>
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/app/resultado/${doc.id}`}
                          onClick={() => Cookies.set('selectedDocId', doc?.id)}
                          style={{
                            backgroundColor: 'white',
                            color: '#5D78FF'
                          }}
                        >
                          USAR
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      </>
    </Page>
  );

  async function getCategorizedDocuments() {
    try {
      const resp = await getConexionOut('documents/getAllDocumentsOpen');
      if (resp != null) {
        setCategorizedDocs(resp.documents);
      }
    } catch (error) {
      console.warn(error);
    }
  }
});

export default HowWorkView;
