/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography, makeStyles } from '@material-ui/core';
import {
  Edit as EditIcon,
  Home as HomeIcon,
  Book as BookIcon,
  User as UserIcon,
  Menu as MenuIcon,
  Users as UsersIcon,
  Grid as GridIcon,
  FileText as FileTextIcon,
  CreditCard as CreditCardIcon,
  List as ListIcon
} from 'react-feather';
import type { Theme } from 'src/theme';
import Logo from 'src/components/Logo';
import LogoDark from 'src/components/LogoDark';
import useAuth from 'src/hooks/useAuth';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: '',
    items: [
      {
        title: 'Inicio',
        icon: HomeIcon,
        href: '/app/home-user'
      }
    ]
  },
  {
    subheader: 'ADMINISTRADOR',
    items: [
      {
        title: 'Categorias',
        icon: GridIcon,
        href: '/app/categories'
      },
      {
        title: 'Categorizar contratos',
        icon: FileTextIcon,
        href: '/app/contracts'
      },
      {
        title: 'Usuarios',
        icon: UsersIcon,
        href: '/app/users-admin'
      },
      {
        title: 'Mis Contratos',
        icon: RestoreOutlinedIcon,
        href: '/app/contracts-user'
      },
      {
        title: 'Mi Perfil',
        icon: UserIcon,
        href: '/app/account'
      },
      {
        title: 'Planes de Suscripcion',
        icon: ListIcon,
        href: '/app/user/user-plan'
      },
      {
        title: 'Preguntas frecuentes',
        icon: HelpOutlinedIcon,
        href: '/app/users/guide/contact'
      }
    ]
  },
  {
    subheader: 'CLIENTE',
    items: [
      {
        title: 'Mis Contratos',
        icon: RestoreOutlinedIcon,
        href: '/app/contracts-user'
      },
      {
        title: 'Mi Perfil',
        icon: UserIcon,
        href: '/app/account'
      },
      {
        title: 'Planes de Suscripcion',
        icon: ListIcon,
        href: '/app/user/user-plan'
      },
      {
        title: 'Preguntas frecuentes',
        icon: HelpOutlinedIcon,
        href: '/app/users/guide/contact'
      }
    ]
  },
  {
    subheader: 'GUEST',
    items: [
      {
        title: 'Guest',
        icon: RestoreOutlinedIcon,
        href: '/login'
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  capitalLetter: {
    textTransform: 'capitalize'
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const settings = window.localStorage.getItem('settings') || 'LIGHT';
  const viewMenu =
    user?.rolDesc === 'cliente'
      ? sections.filter((x) => x.subheader == '' || x.subheader == 'CLIENTE')
      : user?.rolDesc === 'administrador'
        ? sections.filter((x) => x.subheader == '' || x.subheader == 'ADMINISTRADOR')
        : sections.filter((x) => x.subheader == 'GUEST');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={1}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              {settings.includes('LIGHT') ? <Logo width="95vh" /> : <LogoDark />}
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user?.imageProfileUrl || user?.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user?.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              <Link
                component={RouterLink}
                to="/app/account/Subscripción"
                color="primary"
                className={classes.capitalLetter}
              >
                {user?.rolDesc}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {viewMenu.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
