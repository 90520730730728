import { useState, useEffect, forwardRef } from 'react';
import type { FC } from 'react';
import { Box, Button, Grid, Paper, Chip } from '@material-ui/core';
import Page from 'src/components/Page';
import { getConexionOut } from 'src/contexts/Conexion';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from './styleHome';

const PlansView: FC<any> = forwardRef(({ className, refIn, ...rest }) => {
  const classes = useStyles();

  const [plans, setPlans] = useState<any>([]);
  const [isUserLogged, setIsUserLogged] = useState<any>(false);

  useEffect(() => {
    getAllPlans();
    const tmpToken = localStorage.getItem('accessToken');
    const tmpIsLoggend = !!tmpToken;
    setIsUserLogged(tmpIsLoggend);
  }, []);

  return (
    <Page className={classes.root} title="Planes">
      <div className={classes.boxMainContentPlans} ref={refIn}>
        <Box className={classes.titlePlan} mb={3}>
          Un plan para cada necesidad
        </Box>

        <Grid container spacing={3}>
          {plans.map((plan, i) => (
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Paper className={classes.paperPlan} style={{ height: '62vh', maxHeight: '450px', position: 'relative', paddingBottom: '10px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                <Box mb={3}>
                  {plan.quantity === 1 ?
                    <>
                      <span className={classes.priceGeneral}>Paga por documento</span>
                    </>
                    :
                    <>
                      <span className={classes.priceGeneral}>${plan.price}</span>
                      <span style={{ fontFamily: 'Roboto' }}>&nbsp;al mes</span>
                    </>}
                </Box>
                <Box mb={3}>
                  {plan.quantity === 1 ?
                    <>
                      <Chip
                        color="default"
                        size="small"
                        label={<Box>Precio por documento</Box>}
                      />
                    </> :
                    <>
                      {plan.quantity === 5 ?
                        <>
                          <Chip
                            color="default"
                            size="small"
                            label={<Box><s>Antes $35.99/paquete</s></Box>}
                          />
                        </> :
                        <>
                          {plan.quantity === 10 ?
                            <>
                              <Chip
                                color="default"
                                size="small"
                                label={<Box><s>Antes $49.99/paquete</s></Box>}
                              />
                            </> :
                            <></>}
                        </>}
                    </>}
                </Box>
                <Box mb={1} sx={{ fontFamily: 'Roboto !important' }}>
                  Acceso al sistema
                </Box>
                <Box mb={1} sx={{ fontFamily: 'Roboto !important' }}>
                  Actualización de documentos
                </Box>
                <Box mb={1} sx={{ fontFamily: 'Roboto !important' }}>
                  {plan.quantity === 1 ?
                    <>
                      Personaliza el documento
                    </>
                    :
                    <>
                      Personaliza tus documentos
                    </>}
                </Box>
                {plan.quantity === 1 ?
                  <>
                  </>
                  :
                  <>
                    <Box mb={1} sx={{ fontFamily: 'Roboto !important' }}>
                      Duración de documentos listos 15 días
                    </Box>
                  </>}
                <Box mb={3} sx={{ fontFamily: 'Roboto !important' }}>
                  {plan.quantity === 1 ?
                    <>
                      Descarga e Imprime
                    </>
                    :
                    <>
                      Descarga e imprime hasta {plan.quantity} documentos
                    </>}
                </Box>
                <Box textAlign="center" style={{ marginTop: 'auto' }}>
                  <Button
                    variant="outlined"
                    component={RouterLink}
                    to={isUserLogged ? `/buy-plan-landing-auth/${plan.name}` : `/buy-plan-landing/${plan.name}`}
                    style={{
                      backgroundColor: '#5D78FF',
                      color: 'white',
                      marginBottom: '25px'
                    }}
                  >
                    CONTRATAR
                  </Button>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box className={classes.subtitlePlan} mb={1}>
          <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', paddingTop: '5vh' }}>
            <a
              href="/contacto/nosotros"
              className="facebook social"
              style={{ color: '#59428E' }}
              target="_blank"
              rel="noreferrer"
            >
              ¿No encuentras lo que necesitas? Contáctanos
            </a>
          </div>
        </Box>
      </div>

    </Page>
  );

  async function getAllPlans() {
    const resp = await getConexionOut('documents/getAllPlansOpen');
    setPlans(resp);
  }
});

export default PlansView;
