/* eslint-disable arrow-body-style */
import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Hidden, IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import styled from 'styled-components';

interface TopBarProps {
  onMobileNavOpen?: () => void;
}

const RootAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.text.primary};
  box-shadow: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  z-index: ${({ theme }) => theme.zIndex.drawer + 100};
`;

const ToolbarContainer = styled(Toolbar)`
  height: 64px;
`;

const LinkText = styled(RouterLink)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const TopBar: FC<TopBarProps> = ({ onMobileNavOpen }) => {
  return (
    <RootAppBar>
      <ToolbarContainer>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <LinkText
          color="textSecondary"
          component={RouterLink}
          to="/app"
        >
          Dashboard
        </LinkText>
      </ToolbarContainer>
    </RootAppBar>
  );
};

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;
