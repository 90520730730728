/* eslint-disable @typescript-eslint/indent */
import React, { createContext, useEffect, useReducer } from 'react';
import type { FC } from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  getConexion,
  postConexion,
  putConexion,
  postConexionFile,
  deleteConexion,
  postConexionFileShared
} from 'src/contexts/Conexion';
import { setToken, getToken, removeToken } from './indexDbHelpers';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  token: null,
  user: null,
  preference: null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }
  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = accessToken;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setRefresh = (refreshToken: string | null): void => {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
    axios.defaults.headers.common.Authorization = refreshToken;
  } else {
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { token, isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        token,
        user
      };
    }
    case 'UPDATE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { token, user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        token,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'General',
  login: (email: string, password: string) => Promise.resolve(),
  logout: () => { },
  logoutAllSesions: (objUser: any) => Promise.resolve(),
  register: (objRegister: any) => Promise.resolve(),
  uploadAvatar: (image: any, type: string, token?: string) =>
    Promise.resolve({ bucket: '', fullPath: '' }),
  getAccounts: () => Array,
  getUserSubscriptionStatus: () => Object,
  getUserPreferences: () => Object,
  saveUserPreference: (prefId: any) => Promise.resolve(),
  updateSubscription: (objPayment: any, objLogin?: any) => Promise.resolve(),
  checkEmailExistence: (email: string) => Promise.resolve(),
  reset_password: (password: string, token: string) => Promise.resolve(),
  changePassword: (password: string) => Promise.resolve(),
  updateUser: (objUser: any) => Promise.resolve(),
  updateUserOperator: (objUser: any) => Promise.resolve(),
  activeAccount: (email: string, password: string) => Promise.resolve(),
  deactiveAccount: (objUser: any) => Promise.resolve(),
  updateAccountV2: (objUser: any) => Promise.resolve(),
  deactiveOperatorAccount: (objUser: any, type: string) => Promise.resolve(),
  postPayment: (objUser: any) => Object,
  getPaymentRequest: (objUser: any) => Object,
  cancelSubscription: (objPayment: any, objLogin?: any) => Promise.resolve(),
  updatePaymentData: () => Promise.resolve(),
  getRegiterRequest: () => Promise.resolve(),
  getTokenPayment: () => Promise.resolve(),
  saveSuscription: (objPayment: any, objLogin?: any) => Promise.resolve(),
  updatePaymentDataLogin: (email: string) => Promise.resolve(),
  updateExplorer: (objExplorer: any) => Promise.resolve(),
  deleteTokenPayment: () => Promise.resolve()
});

export const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email: string, password: string) => {
    const obj = {
      email,
      password
    };
    const response = await postConexion('users/login', obj);
    const {
      accessToken,
      refreshToken,
      hasActiveSession,
      resetPassword,
      isEmailVerified
    } = response;
    if (hasActiveSession === true) {
      axios.defaults.headers.common.Authorization = accessToken;
      throw new Error(
        `Ya tienes una sesión activa, cierrala e intenta de nuevo`
      );
    }
    if (isEmailVerified === false) {
      throw new Error(`Confirma tu correo electrónico`);
    } else if (resetPassword === true) {
      throw new Error(
        `Has solicitado cambio de contraseña, por favor verifica tu correo y actualiza tu contaseña`
      );
    } else {
      setRefresh(refreshToken);
      setSession(accessToken);
      // Testing IndexDB
      await setToken(accessToken);
    }
    const user = await getConexion('users/me');
    dispatch({
      type: 'LOGIN',
      payload: {
        token: accessToken,
        user: {
          id: 'user.sub',
          avatar: user.imageProfileUrl || '',
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          ...user
        }
      }
    });
  };

  const logout = async () => {
    await postConexion('users/logout', '');
    setSession(null);
    // Cleaning Payment Cookies
    Cookies.remove('accessTokenPy');
    Cookies.remove('refreshTokenPy');
    Cookies.remove('buyPlan');
    Cookies.remove('nameDoc');
    dispatch({ type: 'LOGOUT' });
  };

  const logoutAllSesions = async (objUser: any) => {
    await postConexion('users/endAllSessions', objUser);
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (objRegister: any) => {
    delete objRegister.type_doc_id;
    delete objRegister.policy;
    delete objRegister.submit;
    const response = await postConexion('users', objRegister);
    return response;
  };

  const uploadAvatar = async (image: any, type: string, token?: string) => {
    if (token !== undefined) {
      localStorage.setItem('accessToken', token);
      const response = await postConexionFileShared(
        `documents/saveImage/`,
        image,
        type
      );
      return response;
    } else {
      const response = await postConexionFile(
        `documents/saveImage/`,
        image,
        type
      );
      return response;
    }
  };

  const updateUser = async (objUser: any) => {
    delete objUser.policy;
    delete objUser.submit;
    await postConexion('users/updateUserProfile', objUser);
    const user = await getConexion('users/me');
    dispatch({
      type: 'UPDATE',
      payload: {
        isAuthenticated: true,
        user: {
          id: 'user.sub',
          avatar: user.imageProfileUrl || '',
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          ...user
        }
      }
    });
  };

  const updateUserOperator = async (objUser: any) => {
    delete objUser.submit;
    await postConexion('users/updateOpeProfile', objUser);
  };

  const getAccounts = async () => {
    const userAccounts = await getConexion('documents/getAccountsv2');
    return [...userAccounts.accounts];
  };

  const getUserSubscriptionStatus = async () => {
    const userPrefences = await getConexion('documents/getSuscriptionStatus');
    return userPrefences;
  };

  const getUserPreferences = async () => {
    const userPrefences = await getConexion('users/getUserPreference');
    const objPref = {
      1: false,
      2: false,
      3: false
    };
    userPrefences.map((pref) => {
      objPref[pref.prefId] = pref.enabled;
    });
    return objPref;
  };

  const saveUserPreference = async (prefId: any) => {
    const objUserPreference = {
      preferences: [
        {
          prefId: 1,
          enabled: prefId[1].checked
        },
        {
          prefId: 2,
          enabled: prefId[2].checked
        },
        {
          prefId: 3,
          enabled: prefId[3].checked
        }
      ]
    };
    await postConexion('users/saveUserPreference', objUserPreference);
  };

  const updateExplorer = async (objPayment: any) => {
    const responseUpdate = await putConexion(
      'payments/save_explorer',
      objPayment
    );
    return responseUpdate;
  };

  const updateSubscription = async (objPayment: any, objLogin?: any) => {
    delete objPayment.policy;
    delete objPayment.submit;
    /* let tempToken = "";
    if (objLogin !== undefined) {
      let loginObj = {
        "email": objLogin.email,
        "password": objLogin.password
      }
      const responseLogin = await postConexion("users/login", loginObj);
      const { accessToken } = responseLogin;
      tempToken = accessToken
      setSession(accessToken);
    }
    else {
      tempToken = localStorage.getItem('accessToken');
    }
    const user = await getConexion('users/me') */
    const responseUpdate = await putConexion(
      'payments/subscription',
      objPayment
    );
    if (responseUpdate.Code && responseUpdate.Code === 'Token') {
      const result = {
        Code: responseUpdate.Code,
        Message: responseUpdate.Message
      };
      return result;
    } /*  else if (responseUpdate === 'OK') {
      dispatch({
        type: 'INITIALISE',
        token: tempToken,
        payload: {
          isAuthenticated: true,
          user: {
            id: "user.sub",
            avatar: user.imageProfileUrl || "",
            email: user.email,
            name: user.firstName + ' ' + user.lastName,
            tier: user.plan.namePlan,
            ...user
          }
        }
      });
    }
    else {
      if (tempToken)
        logout();
      dispatch({
        type: 'INITIALISE',
        token: null,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    } */
  };

  const updatePaymentData = async (objPayment: any, objLogin?: any) => {
    const tempToken = localStorage.getItem('accessToken');

    const user = await getConexion('users/me');
    const responseUpdate = await postConexion(
      'payments/update_payment_method',
      {}
    );
    return responseUpdate.Message;
  };

  const updatePaymentDataLogin = async (email: string) => {
    const responseUpdate = await postConexion(
      'payments/update_payment_method_login',
      { email }
    );
    return responseUpdate.Message;
  };

  const saveSuscription = async (objPayment: any, objLogin?: any) => {
    const tempToken = localStorage.getItem('accessToken');

    const user = await getConexion('users/me');
    const responseUpdate = await postConexion(
      'payments/save_suscription',
      objPayment
    );
    return responseUpdate;
  };

  const getTokenPayment = async () => {
    const tempToken = localStorage.getItem('accessToken');

    const user = await getConexion('users/me');
    const tokenResponse = await getConexion('payments/user_token');
    return tokenResponse;
  };

  const deleteTokenPayment = async () => {
    const tempToken = localStorage.getItem('accessToken');

    const user = await getConexion('users/me');
    const tokenResponse = await deleteConexion('payments/delete_token', {});
    return tokenResponse;
  };

  const cancelSubscription = async (objPayment: any, objLogin?: any) => {
    delete objPayment.policy;
    delete objPayment.submit;
    let tempToken = '';
    if (objLogin !== undefined) {
      const loginObj = {
        email: objLogin.email,
        password: objLogin.password
      };
      const responseLogin = await postConexion('users/login', loginObj);
      const { accessToken } = responseLogin;
      tempToken = accessToken;
      setSession(accessToken);
    } else {
      tempToken = localStorage.getItem('accessToken');
    }
    const user = await getConexion('users/me');
    const responseUpdate = await putConexion(
      'payments/update_token',
      objPayment
    );
    if (responseUpdate === 'OK') {
      dispatch({
        type: 'INITIALISE',
        token: tempToken,
        payload: {
          isAuthenticated: true,
          user: {
            id: 'user.sub',
            avatar: user.imageProfileUrl || '',
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            // tier: user.plan.namePlan,
            ...user
          }
        }
      });
    } else {
      if (tempToken) {
        // logout();
        dispatch({
          type: 'INITIALISE',
          token: null,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    }
  };

  const checkEmailExistence = async (email: string) => {
    const objEmail = {
      email
    };
    await postConexion('users/reset-password-mail', objEmail);
  };

  const reset_password = async (password: string, token: string) => {
    const objReset = {
      password,
      token
    };
    const resp = await postConexion('users/reset-password', objReset);
    // const { Message } = resp;
    // const { value } = Message;
    // const { email } = value;
    // const objEndAll = {
    //   email,
    //   password,
    //   token
    // };
    // logoutAllSesions(objEndAll);
  };

  const changePassword = async (password: string) => {
    const objNewPass = {
      password
    };
    const response = await postConexion('users/changePass', objNewPass);
    if (response === 'OK') logout();
  };

  const activeAccount = async (email: string, password: string) => {
    const objUser = {
      email,
      password
    };
    await postConexion('users/enableAccount', objUser);
  };

  const deactiveAccount = async (objUser: any) => {
    delete objUser.policy;
    delete objUser.submit;
    objUser.is_deleted = true;
    await postConexion('users/updateUserProfile', objUser);
    logout();
  };

  const updateAccountV2 = async (objUser: any) => {
    await postConexion('users/updateUserProfile', objUser);
  };

  const deactiveOperatorAccount = async (objUser: any, type: string) => {
    if (type === 'deactive') objUser.is_deleted = true;
    else objUser.is_deleted = false;
    await postConexion('users/updateOpeProfile', objUser);
  };

  const postPayment = async (objUser: any) => {
    const response = await postConexion('payments/payment', objUser);
    return response.Message;
  };

  const getPaymentRequest = async (objUser: any) => {
    const response = await postConexion('payments/generate', objUser);
    return response.Message;
  };

  const getRegiterRequest = async (objUser: any) => {
    const response = await postConexion('payments/save_token', objUser);
    return response;
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const tmpAccToken = Cookies.get('accessTokenPy');
        const accessToken = tmpAccToken || window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          axios.defaults.headers.common.Authorization = accessToken;
          setSession(accessToken);
          const user = await getConexion('users/me');
          dispatch({
            type: 'INITIALISE',
            token: accessToken,
            payload: {
              isAuthenticated: true,
              user: {
                id: 'user.sub',
                avatar: user.imageProfileUrl || '',
                email: user.email,
                name: `${user.firstName} ${user.lastName}`,
                // tier: user.plan.namePlan || "",
                ...user
              }
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            token: null,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          token: null,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'General',
        login,
        register,
        logout,
        logoutAllSesions,
        uploadAvatar,
        getAccounts,
        getUserSubscriptionStatus,
        getUserPreferences,
        saveUserPreference,
        updateSubscription,
        checkEmailExistence,
        reset_password,
        changePassword,
        updateUser,
        updateUserOperator,
        activeAccount,
        deactiveAccount,
        updateAccountV2,
        deactiveOperatorAccount,
        postPayment,
        getPaymentRequest,
        cancelSubscription,
        updatePaymentData,
        getRegiterRequest,
        saveSuscription,
        getTokenPayment,
        updatePaymentDataLogin,
        updateExplorer,
        deleteTokenPayment
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
