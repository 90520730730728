import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => (
  <img
    alt="Adjudica Innovation Hub Logo"
    title="Adjudica Innovation Hub Logo"
    src="/static/logo.svg"
    width={props.width && "50"}
    height={props.height && "40"}
    {...props}
  />
);

export default Logo;
