import { useEffect } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import LandingAdjudicaView from './LandingAdjudicaView';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView: FC = () => {
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <Page
      className={classes.root}
      title="Adjudica | La nueva forma de hacer derecho"
    >
      <LandingAdjudicaView />
    </Page>
  );
};

export default HomeView;
