import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const LogoDark: FC<LogoProps> = (props) => (
  <img
    alt="Adjudica Innovation Hub Logo"
    title="Adjudica Innovation Hub Logo"
    src="/static/dark_logo.svg"
    width="50"
    height="40"
    {...props}
  />
);

export default LogoDark;
