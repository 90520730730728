import React, { forwardRef, FC } from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Tooltip,
  Fab,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MarkunreadMailboxTwoToneIcon from '@material-ui/icons/MarkunreadMailboxTwoTone';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: '#001930',
    background:
      'linear-gradient(89.88deg, #3E2675 1.45%, #7F41A3 48.07%, #F2AFBA 98.39%)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    color: 'white'
  },
  fab: {
    margin: theme.spacing(2)
  },
  underline: {
    maxWidth: '30%',
    margin: '-2% 35% 3% 35%'
  },
  copyRight: {
    color: 'white'
  },
  marginCopy: {
    marginLeft: 0
  },
  margin: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'green !important',
      color: 'white'
    }
  },
  copyright: {
    fontSize: '0.6rem',
    color: 'white'
  }
}));

const Footer: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  const classes = useStyles();

  const actualYear = new Date().getFullYear();

  return (
    <div ref={ref} className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Box mt={3}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
              md={4}
              xs={12}
            >
              <Typography variant="overline" style={{ color: '#3adbba' }}>
                MANTENGÁMONOS CONECTADOS
              </Typography>
              <div>
                <Tooltip title="Síguenos en YouTube" aria-label="youtube">
                  <Fab
                    target="_blank"
                    href="https://www.youtube.com/channel/UCY7j_0GSkArYLNY1PBh4dEw"
                    size="small"
                    color="secondary"
                    className={classes.fab}
                  >
                    <YouTubeIcon fontSize="medium" />
                  </Fab>
                </Tooltip>
                <Tooltip title="Síguenos en Instagram" aria-label="instagram">
                  <Fab
                    target="_blank"
                    href="https://www.instagram.com/AdjudicaLaw/"
                    size="small"
                    color="secondary"
                    className={classes.fab}
                  >
                    <InstagramIcon fontSize="medium" />
                  </Fab>
                </Tooltip>
                <Tooltip title="Síguenos en LinkedIn" aria-label="linkedin">
                  <Fab
                    target="_blank"
                    href="https://www.linkedin.com/company/adjudicalaw"
                    size="small"
                    color="secondary"
                    className={classes.fab}
                  >
                    <LinkedInIcon fontSize="medium" />
                  </Fab>
                </Tooltip>
              </div>
              <div />
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              item
              md={4}
              xs={12}
            >
              <Typography variant="overline" style={{ color: '#3adbba' }}>
                Condiciones
              </Typography>
              <div>
                <Link
                  href="/policies/terms"
                  variant="overline"
                  style={{ color: 'white' }}
                >
                  Términos y condiciones
                </Link>
              </div>
              <div>
                <Link
                  href="/policies/privacy"
                  variant="overline"
                  style={{ color: 'white' }}
                >
                  Política de privacidad
                </Link>
              </div>
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              item
              md={4}
              xs={12}
            >
              <Typography variant="overline" style={{ color: '#3adbba' }}>
                Houston tenemos un problema
              </Typography>
              <div>
                <Link
                  href="/contacto"
                  variant="overline"
                  style={{ color: 'white' }}
                >
                  Contáctanos
                </Link>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3} mx={4}>
          <Grid container direction="row" spacing={3}>
            <Typography
              className={classes.copyright}
              variant="caption"
              color="textSecondary"
              align="center"
            >
              <strong>Copyright {actualYear}® ADJUDICA.</strong> Todos los
              derechos reservados. ADJUDICA solo proporciona información y
              software. ADJUDICA no es un "servicio de referencia de abogados" o
              un "Legal Marketplace" y no brinda asesoramiento legal ni
              participa en ninguna representación legal. ADJUDICA no es una
              firma de abogados o un sustituto de un abogado o firma de
              abogados. El uso de ADJUDICA está sujeto a nuestros Términos y
              Condiciones y Acuerdo de privacidad.
            </Typography>
          </Grid>
        </Box>
      </Container>
    </div>
  );
});

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
