import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faFacebookF,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";

export default function SocialFollow() {
  return (
    <div className="social-container">
      <a
        href="https://www.facebook.com/AdjudicaLaw"
        className="facebook social"
        style={{ color: 'white', paddingLeft: '0px' }}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faFacebookF}
          size="2x"
        />
      </a>
      <a
        href="https://twitter.com/AdjudicaLaw"
        className="twitter social"
        style={{ color: 'white', paddingLeft: '20px' }}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faTwitter}
          size="2x"
        />
      </a>
      <a
        href="https://www.instagram.com/AdjudicaLaw/"
        className="instagram social"
        style={{ color: 'white', paddingLeft: '20px' }}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faInstagram}
          size="2x"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/adjudicalaw"
        className="linkedin social"
        style={{ color: 'white', paddingLeft: '20px' }}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faLinkedinIn}
          size="2x"
        />
      </a>
    </div>
  );
}
