import { forwardRef, FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import { postConexionShared } from 'src/contexts/Conexion';
import { REDIRECTION_DATAWEB } from 'src/constants';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import wait from 'src/utils/wait';
import LoadingLogo from 'src/components/LoadingLogo';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import ErrorIcon from '@material-ui/icons/Error';
import Page from 'src/components/Page';
import SocialFollow from 'src/components/SocialFollow';
import clsx from 'clsx';
import useStyles from './styleHome';

const HomeView: FC<any> = forwardRef(({ className, refIn, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState<any>(false);
  const [isPayment, setIsPayment] = useState<any>(false);
  const [dialogParams, setDialogParams] = useState<any>({
    error: false,
    icon: <CheckCircleSharpIcon style={{ fontSize: 99, color: '#10C950' }} />,
    title: '¡El pago se realizó con éxito!',
    label: 'Continua con el proceso para descargar tu contrato.',
    btn: 'CONTINUAR',
    message: {}
  });

  // Parse query parameters
  const params = new URLSearchParams(window.location.search);
  const merchantTransactionId = params.get('merchantTransactionId');

  const url = window.location.search;
  const vecPayment = url.split('%2F');
  useEffect(() => {
    if (url !== '' && vecPayment.length > 0 && vecPayment[0] !== '?:flagLogin=1') {
      setIsPayment(true);
      dataFastPayment();
    }
  }, []);

  async function dataFastPayment() {
    const path = `/v1/checkouts/${vecPayment[3]}/payment`;
    const resp = await postConexionShared('payments/dataFastPayment', { path, merchantTransactionId });
    // TODO:aqui validar si el pago con (resp.value.result.code) es exitoso de serlo llamar a la api para guardar el plan correspondiente
    if (resp.message.status === 'OK') {
      const retreivedAccessTokenPy = Cookies.get('accessTokenPy');
      const retreivedRefreshTokenPy = Cookies.get('refreshTokenPy');
      localStorage.setItem('accessToken', retreivedAccessTokenPy);
      localStorage.setItem('refreshToken', retreivedRefreshTokenPy);
      await executePayment();
    } else {
      setDialogParams({
        error: true,
        icon: <ErrorIcon
          color="error"
          style={{ fontSize: 99 }}
        />,
        title: 'No se pudo realizar el pago',
        label: 'Puedes intentarlo de nuevo.',
        btn: 'CERRAR',
        message: resp.message
      });
      setOpen(true);
    }
  }

  async function executePayment() {
    const planToSendPy = Cookies.get('buyPlan');
    const resp = await postConexionShared('documents/saveUserPlan', {
      namePlan: planToSendPy
    });
    if (resp === 'OK') {
      // setOpen(true);
      enqueueSnackbar('Su suscripción se ha actualizado con éxito', { variant: 'success' });
      await wait(2000);
      history.push("/app/user/user-plan");
    } else {
      setDialogParams({
        error: true,
        icon: <ErrorIcon
          color="error"
          style={{ fontSize: 99 }}
        />,
        title: 'No se pudo realizar el pago',
        label: 'Puedes intentarlo de nuevo.',
        btn: 'PAGAR',
        message: {}
      });
      setOpen(true);
    }
  }

  const handleClose = () => {
    reloadPage();
    setOpen(false);
    window.location.replace(`${REDIRECTION_DATAWEB}app/user/user-plan`);
  };

  return (
    <>
      <Dialog
        open={open}
        // style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', backdropFilter: 'blur(10px)' }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className={clsx(classes.boxIconX)} />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className={clsx(classes.containerCard)}>
              {dialogParams.icon}
            </Box>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Box
                mt={3}
                fontWeight="fontWeightBold"
                fontSize={24}
              >
                {dialogParams.title}
              </Box>
              <Box fontSize={16}>{dialogParams.label}</Box>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={clsx(classes.containerCard)} style={{ padding: 0 }}>
          <Box
            style={{ width: '100%', padding: '0 100px' }}
            mb={10}
            mt={3}
          >
            <Button
              onClick={reloadPage}
              component={RouterLink}
              style={{ width: '100%' }}
              to="/app/user/user-plan/"
              variant="contained"
              color="primary"
            >
              {dialogParams.btn}
            </Button>
          </Box>
        </DialogActions>
        <Box style={{ margin: '10px' }}>
          {dialogParams.error ? (
            <code>
              <strong>DETALLES:</strong> {JSON.stringify(dialogParams.message)}
            </code>
          ) : (
            <></>
          )}
        </Box>
      </Dialog>
      {isPayment ?
        <div style={{ height: '100vh', marginTop: '-80px', position: 'relative' }}>
          <LoadingLogo />
        </div> :
        <Page
          className={classes.root}
          title="Cómo funciona"
        >
          <>
            <div ref={refIn}>
              <Box className={classes.boxMainContentHero}>
                <Grid
                  container
                  spacing={0}
                >
                  <Grid
                    item
                    xs={12}
                    md={5}
                    style={{ paddingTop: 18 }}
                  >
                    <Box className={classes.titleHero}>
                      Documentos legales donde quieras, cuando quieras
                    </Box>
                    <Box className={classes.subTitleHero}>
                      Confiables, seguros y al mejor precio
                    </Box>
                    <Box mb={6}>
                      <Button
                        variant="contained"
                        className={classes.exploraButton}
                        onClick={() => history.push('/#acerca')}
                        id="explorabutton"
                      >
                        Explora las funciones
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        variant="contained"
                        className={classes.comoButton}
                        onClick={() => history.push('/#comofunciona')}
                      >
                        <FontAwesomeIcon icon={faPlayCircle} />
                        &nbsp;Cómo funciona
                      </Button>
                    </Box>
                    <Box mb={3}>MANTENGÁMONOS CONECTADOS</Box>
                    <Box>
                      <SocialFollow />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={7}
                  >
                    <div className={classes.divVideoHero}>
                      <iframe
                        width="90%"
                        height="90%"
                        className={classes.videoLanding}
                        src="https://www.youtube.com/embed/IRJBov-QehM"
                        title="Adjudica"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      />{' '}
                      <div className={classes.diplayLineBreak}>Space</div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </>
        </Page>
      }
    </>
  );

  async function reloadPage() {
    setOpen(false);
  }
});

export default HomeView;
