export const APP_VERSION = '2.0.16092024';

export const MASTER_ACCOUNT = 'mrjleo1989@gmail.com';

// export const REDIRECTION_DATAWEB = 'http://localhost:3000/';
// export const REDIRECTION_DATAWEB = 'https://staging.adjudica.ai/';
export const REDIRECTION_DATAWEB = 'https://adjudica.ai/';

// export const DATAWEBNEW = 'https://eu-test.oppwa.com';
export const DATAWEBNEW = 'https://eu-prod.oppwa.com';

export const ENABLE_REDUX_DEV_TOOLS = true;

// STAGING=>000.100.112
// PROD=>000.000.000
// export const DATAFAST_SUCCESS_CODE = '000.100.112';
export const DATAFAST_SUCCESS_CODE = '000.000.000';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK'
};
