/* eslint-disable react/jsx-curly-brace-presence */
import React, {
  useState,
  forwardRef,
  FC
} from 'react';
import {
  Box,
  Button,
  Grid,
  Slide,
  Dialog
} from '@material-ui/core';
import Page from 'src/components/Page';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import Play from '../../../assets/img/play.svg';
import logoBack from '../../../assets/img/logo-back.svg';
import useStyles from './styleHome';

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const AboutView: FC<any> = forwardRef(({ className, refIn, ...rest }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [status, setStatus] = useState<any>(true);
  const [cards, setCards] = useState<any>([
    {
      id: 1,
      label: 'Documentos legales en minutos',
      content:
        'Con los servicios legales de Adjudica, genera tus propios documentos y ahorra hasta el 90% de tu tiempo.',
      status: true,
      icon: <ExpandLessIcon />
    },
    {
      id: 2,
      label: 'Trabaja y vive con confianza',
      content:
        'Encuentra distintos documentos legales elaborados por abogados para tus necesidades habituales. Accede desde cualquier dispositivo, haz click, descarga e imprime.',
      status: false,
      icon: <ExpandMoreIcon />
    },
    {
      id: 3,
      label: 'Transforma tu día a día',
      content:
        'Qué el tema legal no sea una traba, obtén mejor precio y accesibilidad para ti, tus negocios y tu vida',
      status: false,
      icon: <ExpandMoreIcon />
    }
  ]);

  const [open, setOpen] = React.useState<any>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page className={classes.root} title="Quienes somos">
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box className={classes.exitDialog}>
          <CloseIcon onClick={handleClose} />
        </Box>
        <div className={classes.divVideoAdj}>
          <iframe
            width="90%"
            height="90%"
            src="https://www.youtube.com/embed/0SJ-9rVml68"
            title="Adjudica"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />{' '}
        </div>
      </Dialog>

      <>
        <div className={classes.boxMainContentDesc} ref={refIn}>
          <Box className={classes.btnPlay} onClick={handleClickOpen}>
            <img alt="logo" src={String(Play)} />
            <p className={classes.comoTxt}>CÓMO FUNCIONA</p>
          </Box>
          <Grid container spacing={0}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box className={classes.titleDesc}>
                <img
                  alt="logo"
                  className={classes.boxImage}
                  src={String(logoBack)}
                />
              </Box>
              <Box className={classes.subTitleDesc}>
                Somos la pieza que faltaba
              </Box>

              <>
                <Grid container spacing={1} className={classes.gridSomos}>
                  <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                    {cards.map((item, index) => (
                      <div className={classes.contentDescription} key={index}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          endIcon={item.icon}
                          classes={{
                            root: item.status
                              ? classes.btnInitActive
                              : classes.btnInit,
                            label: classes.labelBtn
                          }}
                          onClick={() => handleChangeOptions(item, index)}
                        >
                          {item.label}
                        </Button>
                        {item.status ? (
                          <Box
                            className={classes.boxContent}
                            sx={{
                              fontFamily: 'Roboto !important'
                            }}
                          >
                            {item.content}
                          </Box>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box className={classes.boxTargetAdj}>
                <img
                  alt="logo"
                  className={classes.boxImage}
                  src={String(logoBack)}
                />
                <Box className={classes.boxTitle}>
                  HACEMOS EL DERECHO SIMPLE
                </Box>
                <Box className={classes.boxHashtag}>
                  #SomosLaPiezaQueFaltaba
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </>
    </Page>
  );

  function handleChangeOptions(
    item: {
      id: number;
      label: string;
      content: string;
      status: boolean;
      icon: JSX.Element;
    },
    indexActual
  ) {
    const tempCards = cards;
    for (let index = 0; index < tempCards.length; index++) {
      // const element = array[index];
      if (index == indexActual) {
        if (tempCards[index].id === item.id) {
          tempCards[index].status = !tempCards[index].status;
          tempCards[index].icon = tempCards[index].status ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          );
        }
      }
    }
    setStatus(!status);
    setCards(tempCards);
  }
});

export default AboutView;
