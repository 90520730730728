const objFromArrayPlans = (arr: any[]) =>
  arr.reduce((accumulator, current, index) => {
    let indexId = 4;
    switch (current.name) {
    case 'paquete10':
      indexId = 0;
      break;
    case 'paquete20':
      indexId = 1;
      break;
    }
    accumulator[indexId] = current;
    return accumulator;
  }, {});

export default objFromArrayPlans;
