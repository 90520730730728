/* eslint-disable @typescript-eslint/indent */
import { useState, useEffect, useRef, FC } from 'react';
import {
  Box,
  Link,
  Grid,
  Button,
  Drawer,
  ListItem,
  List,
  IconButton
} from '@material-ui/core';
import LoadingLogo from 'src/components/LoadingLogo';
import Page from 'src/components/Page';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from './styleHome';
import logoColor from '../../../assets/img/logo-color.svg';
import HomeView from './HomeView';
import AboutView from './AboutView';
import HowWorkView from './HowWorkView';
import PlansView from './PlansView';
import Footer from './Footer';

const LandingAdjudicaView: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [flag, setFlag] = useState<any>(false);
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const [isLogin, setIsLogin] = useState<any>(!!(accessToken && refreshToken));
  // Testing Cookie
  const accessTokenPy = Cookies.get('accessTokenPy');
  const refreshTokenPy = Cookies.get('refreshTokenPy');
  const [isLoginPy] = useState<any>(!!(accessTokenPy && refreshTokenPy));
  const homeRef = useRef<HTMLInputElement | null>(null);
  const aboutRef = useRef<HTMLInputElement | null>(null);
  const howWorkRef = useRef<HTMLInputElement | null>(null);
  const plansRef = useRef<HTMLInputElement | null>(null);
  const [isPayment, setIsPayment] = useState<any>(false);
  const [menu, setMenu] = useState<any>([
    { id: 1, name: 'INICIO', link: '#inicio', isActived: true, style: '' },
    { id: 2, name: 'CÓMO FUNCIONA', link: '#comofunciona', isActived: false, style: '' },
    { id: 3, name: 'DOCUMENTOS', link: '#acerca', isActived: false, style: '' },
    { id: 4, name: 'PLANES', link: '#planes', isActived: false, style: '' }
  ]);

  useEffect(
    () =>
      history.listen((location) => {
        handleScroll(location.hash);
      }),
    [history]
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  /* useEffect(() => {
    setIsPayment(true);
    const timer = setTimeout(() => {
      setIsPayment(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []); */

  return (
    <>
      {isPayment ?
        <LoadingLogo /> :
        <Page className={classes.root} title="Inicio">
          <Box className={classes.backgroundHero}>
            <Box className={classes.boxGeneralNavBar}>
              <Grid container spacing={0}>
                <Grid item xs={5}>
                  <img alt="logo" style={{ width: 150 }} src={String(logoColor)} />
                </Grid>
                <Grid item xs={7}>
                  <Grid container spacing={0} className={classes.menuNoVisible}>
                    <Grid item xs={7} className={classes.navContainer}>
                      {menu.map((m) => (
                        <a
                          key={m.id}
                          href={m.link}
                          className={
                            m.isActived ? classes.navItemActivated : classes.navItem
                          }
                          onClick={() => handlerChangeMenu(m)}
                        >
                          {m.name}
                        </a>
                      ))}
                    </Grid>
                    <Grid item xs={5}>
                      {isLogin || isLoginPy ? (
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box className={classes.creaButton}>
                              <Link
                                href="/app/home-user"
                                variant="overline"
                                style={{
                                  color: 'white',
                                  textDecoration: 'none',
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '100%',
                                  fontFamily: 'Poppins',
                                  fontWeight: 700,
                                  fontSize: 16,
                                  lineHeight: '15px'
                                }}
                              >
                                DASHBOARD
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box className={classes.creaButton}>
                              <Link
                                href="/register"
                                variant="overline"
                                style={{
                                  color: 'white',
                                  textDecoration: 'none',
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '100%',
                                  fontFamily: 'Poppins',
                                  fontWeight: 700,
                                  fontSize: 16,
                                  lineHeight: '15px'
                                }}
                              >
                                CREA TU DOCUMENTO
                              </Link>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              className={classes.loginButton}
                              onClick={() => history.push('/login')}
                            >
                              Iniciar sesión
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className={classes.visible}>
                    <IconButton
                      className={classes.visible}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={() => setIsDrawerOpen(true)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Drawer
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                classes={{ paper: classes.drawGeneral }}
              >
                <List className={classes.drawer}>
                  {menu.map((m) => (
                    <ListItem
                      button
                      key={m.id}
                      onClick={() => handlerChangeMenu(m)}
                    >
                      <a
                        href={m.link}
                        className={
                          m.isActived
                            ? classes.sideBarActivatedMenu
                            : classes.sideBarNoActivatedMenu
                        }
                      >
                        {m.name}
                      </a>
                    </ListItem>
                  ))}
                  {isLogin || isLoginPy ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box className={classes.creaButtonResponsive}>
                          <Link
                            href="/app/home-user"
                            variant="overline"
                            style={{
                              color: 'white',
                              textDecoration: 'none',
                              marginLeft: 5,
                              marginRight: 5,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                              fontFamily: 'Poppins',
                              fontWeight: 700,
                              fontSize: 16,
                              lineHeight: '15px'
                            }}
                          >
                            DASHBOARD
                          </Link>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box className={classes.creaButtonResponsive} style={{ margin: '0px 15px' }}>
                          <Link
                            href="/register"
                            variant="overline"
                            style={{
                              color: 'white',
                              textDecoration: 'none',
                              paddingLeft: 5,
                              paddingRight: 5,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                              fontFamily: 'Poppins',
                              fontWeight: 700,
                              fontSize: 16,
                              lineHeight: '15px'
                            }}
                          >
                            CREA TU DOCUMENTO
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item xs={12} style={{ margin: '0px 15px' }}>
                        <Button
                          variant="contained"
                          className={classes.loginButtonResponsive}
                          onClick={() => history.push('/login')}
                        >
                          Iniciar sesión
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </List>
              </Drawer>
            </Box>

            <HomeView refIn={homeRef} />
          </Box>

          <AboutView refIn={aboutRef} />
          <HowWorkView refIn={howWorkRef} />
          <PlansView refIn={plansRef} />
          <Footer />
        </Page>
      }
    </>
  );

  function handlerChangeMenu(menuIn) {
    const tempMenu = menu;
    for (const m of tempMenu) {
      if (m.id === menuIn.id) {
        m.isActived = true;
      } else {
        m.isActived = false;
      }
    }
    setMenu(tempMenu);
    setFlag(!flag);
  }

  function handleScroll(section: string) {
    switch (section) {
      case '#inicio':
        homeRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case '#comofunciona':
        aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case '#acerca':
        howWorkRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case '#planes':
        plansRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  }
};

export default LandingAdjudicaView;
