import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    // Where user cames from selected plan in Landing page
    const existTempBuyPlan = JSON.parse(localStorage.getItem('tempBuyPlan'));
    if (existTempBuyPlan !== null) {
      return <Redirect to={existTempBuyPlan?.url} />;
    }
    // Where user cames from selected document in Landing page
    const selectedDocId = Cookies.get('selectedDocId');
    const parsedSelectedDocId = selectedDocId ? Number(selectedDocId) : 0;
    if (parsedSelectedDocId > 0) {
      const tmpUrl = `/app/resultado/${parsedSelectedDocId}`;
      return <Redirect to={tmpUrl} />;
    } else {
      return <Redirect to="/app/home-user" />;
    }
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
