import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'axios';
import { getConexion, postConexion, deleteConexion, postConexionFile } from 'src/contexts/Conexion';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  isLoaded: false,
  lists: {
    byId: {},
    allIds: []
  },
  cards: {
    byId: {},
    allIds: []
  },
  members: {
    byId: {},
    allIds: []
  },
  workspaces: {
    byId: {},
    allIds: []
  },
  documents: {
    byId: {},
    allIds: []
  },
  templates: {
    byId: {},
    allIds: []
  },
  templatesDoc: {
    byId: {},
    allIds: []
  },
  pages: {
    byId: {},
    allIds: []
  },
  elements: {
    byId: {},
    allIds: []
  },
  items: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'interiew',
  initialState,
  reducers: {
    getBoard(state, action) {
      const board = action.payload;
      const objResponse = {
        workspaces: [...board.workspaces],
        documents: [...board.documents],
        templates: [...board.templates],
        templatesDoc: [...board.templatesDoc],
        pages: [...board.pages],
        elements: [...board.elements],
        items: [...board.items]
      };
      // new templatesDoc
      state.workspaces.byId = objFromArray(objResponse.workspaces);
      state.workspaces.allIds = Object.keys(state.workspaces.byId).map(Number);
      state.documents.byId = objFromArray(objResponse.documents);
      state.documents.allIds = Object.keys(state.documents.byId).map(Number);
      state.templates.byId = objFromArray(objResponse.templates);
      state.templates.allIds = Object.keys(state.templates.byId).map(Number);
      state.templatesDoc.byId = objFromArray(objResponse.templatesDoc);
      state.templatesDoc.allIds = Object.keys(state.templatesDoc.byId).map(Number);
      state.pages.byId = objFromArray(objResponse.pages);
      state.pages.allIds = Object.keys(state.pages.byId).map(Number);
      state.elements.byId = objFromArray(objResponse.elements);
      state.elements.allIds = Object.keys(state.elements.byId).map(Number);
      state.items.byId = objFromArray(objResponse.items);
      state.items.allIds = Object.keys(state.items.byId).map(Number);
      state.isLoaded = true;
    },
    // Template preference
    saveGeneralSelectedTemp(state, action) {
      const selectedTemp = action.payload;
      state.documents.byId[selectedTemp.documentId].generalSelectedTemp = selectedTemp.fullPath;
    },
    // Workspace
    createWorkspace(state, action) {
      const workspace = action.payload;
      state.workspaces.byId[workspace.id] = workspace;
      state.workspaces.allIds.push(workspace.id);
    },
    deleteWorkspace(state, action) {
      const { workspaceId } = action.payload;
      state.workspaces.byId = _.omit(state.workspaces.byId, parseInt(workspaceId));
      _.pull(state.workspaces.allIds, parseInt(workspaceId));
    },
    updateWorkspace(state, action) {
      const workspace = action.payload;
      state.workspaces.byId[workspace.id] = workspace;
    },
    // documents
    createDocument(state, action) {
      const document = action.payload;
      state.documents.byId[document.id] = document;
      state.documents.allIds.push(document.id);
      state.workspaces.byId[document.workspaceId].documentsIds.push(document.id);
    },
    deleteDocument(state, action) {
      const { documentId } = action.payload;
      const { workspaceId } = state.documents.byId[documentId];
      state.documents.byId = _.omit(state.documents.byId, documentId);
      _.pull(state.documents.allIds, documentId);
      _.pull(state.workspaces.byId[workspaceId].documentsIds, documentId);
    },
    updateDocument(state, action) {
      const document = action.payload;
      state.documents.byId[document.id] = document;
    },
    // templates
    createTemplate(state, action) {
      const template = action.payload;
      state.templates.byId[template.id] = template;
      state.templates.allIds.push(template.id);
      state.documents.byId[template.documentId].templatesIds.push(template.id);
    },
    updateTemplate(state, action) {
      const template = action.payload;
      state.templates.byId[template.id] = template;
    },
    deleteTemplate(state, action) {
      const { templateId } = action.payload;
      const { documentId } = state.templates.byId[templateId];
      state.templates.byId = _.omit(state.templates.byId, templateId);
      _.pull(state.templates.allIds, templateId);
      _.pull(state.documents.byId[documentId].templatesIds, templateId);
    },
    deleteTemplateWord(state, action) {
      const { templateDocId } = action.payload;
      const { documentId } = state.templatesDoc.byId[templateDocId];
      state.templatesDoc.byId = _.omit(state.templatesDoc.byId, templateDocId);
      _.pull(state.templatesDoc.allIds, templateDocId);
      _.pull(state.documents.byId[documentId].tempDocsIds, templateDocId);
    },
    // pages
    createPage(state, action) {
      const page = action.payload;
      state.pages.byId[page.id] = page;
      state.pages.allIds.push(page.id);
      state.documents.byId[page.documentId].pagesIds.push(page.id);
    },
    updatePage(state, action) {
      const page = action.payload;
      state.pages.byId[page.id] = page;
    },
    clearPage(state, action) {
      const { pageId } = action.payload;
      const { elementsIds } = state.pages.byId[pageId];
      state.pages.byId[pageId].elementsIds = [];
      state.elements.byId = _.omit(state.elements.byId, elementsIds);
      _.pull(state.elements.allIds, ...elementsIds);
    },
    deletePage(state, action) {
      const { pageId } = action.payload;
      const { documentId } = state.pages.byId[pageId];
      state.pages.byId = _.omit(state.pages.byId, pageId);
      _.pull(state.pages.allIds, pageId);
      _.pull(state.documents.byId[documentId].pagesIds, pageId);
    },
    // Element
    moveElement(state, action) {
      const { elementId, position } = action.payload;
      const { pageId: sourcePageId } = state.elements.byId[elementId];
      _.pull(state.pages.byId[sourcePageId].elementsIds, elementId);
      state.pages.byId[sourcePageId].elementsIds.splice(position, 0, elementId);
    },
    createElement(state, action) {
      const element = action.payload;
      state.elements.byId[element.id] = element;
      state.elements.allIds.push(element.id);
      state.pages.byId[element.pageId].elementsIds.push(element.id);
    },
    deleteElement(state, action) {
      const { elementId, pageId } = action.payload;
      state.elements.byId = _.omit(state.elements.byId, elementId);
      _.pull(state.elements.allIds, elementId);
      _.pull(state.pages.byId[pageId].elementsIds, elementId);
    },
    clearElement(state, action) {
      const { elementId } = action.payload;
      const { itemsIds } = state.elements.byId[elementId];
      state.elements.byId[elementId].itemsIds = [];
      state.items.byId = _.omit(state.items.byId, itemsIds);
      _.pull(state.items.allIds, ...itemsIds);
    },
    updateElement(state, action) {
      const element = action.payload;
      state.elements.byId[element.id] = element;
    },
    // Item
    moveItem(state, action) {
      const { itemId, position } = action.payload;
      const { elementId: sourceElementId } = state.items.byId[itemId];
      _.pull(state.elements.byId[sourceElementId].itemsIds, itemId);
      state.elements.byId[sourceElementId].itemsIds.splice(position, 0, itemId);
    },
    createItem(state, action) {
      const item = action.payload;
      state.items.byId[item.id] = item;
      state.items.allIds.push(item.id);
      state.elements.byId[item.elementId].itemsIds.push(item.id);
    },
    deleteItem(state, action) {
      const { itemId } = action.payload;
      const { elementId } = state.items.byId[itemId];
      state.items.byId = _.omit(state.items.byId, itemId);
      _.pull(state.items.allIds, itemId);
      _.pull(state.elements.byId[elementId].itemsIds, itemId);
    },
    updateItem(state, action) {
      const item = action.payload;
      _.merge(state.items.byId[item.id], item);
    },
    // List
    updateList(state, action) {
      const { list } = action.payload;
      state.lists.byId[list.id] = list;
    },
    clearList(state, action) {
      const { listId } = action.payload;
      const { cardIds } = state.lists.byId[listId];
      state.lists.byId[listId].cardIds = [];
      state.cards.byId = _.omit(state.cards.byId, cardIds);
      _.pull(state.cards.allIds, ...cardIds);
    },
    deleteList(state, action) {
      const { listId } = action.payload;
      state.lists.byId = _.omit(state.lists.byId, listId);
      _.pull(state.lists.allIds, listId);
    },
    createCard(state, action) {
      const { card } = action.payload;
      state.cards.byId[card.id] = card;
      state.cards.allIds.push(card.id);
      state.lists.byId[card.listId].cardIds.push(card.id);
    },
    updateCard(state, action) {
      const { card } = action.payload;
      _.merge(state.cards.byId[card.id], card);
    },
    moveCard(state, action) {
      const { cardId, position, listId } = action.payload;
      const { listId: sourceListId } = state.cards.byId[cardId];
      _.pull(state.lists.byId[sourceListId].cardIds, cardId);
      if (listId) {
        state.cards.byId[cardId].listId = listId;
        state.lists.byId[listId].cardIds.splice(position, 0, cardId);
      } else {
        state.lists.byId[sourceListId].cardIds.splice(position, 0, cardId);
      }
    },
    deleteCard(state, action) {
      const { cardId } = action.payload;
      const { listId } = state.cards.byId[cardId];
      state.cards.byId = _.omit(state.cards.byId, cardId);
      _.pull(state.cards.allIds, cardId);
      _.pull(state.lists.byId[listId].cardIds, cardId);
    },
    addComment(state, action) {
      const { comment } = action.payload;
      const card = state.cards.byId[comment.cardId];
      card.comments.push(comment);
    },
    addChecklist(state, action) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists.push(checklist);
    },
    updateChecklist(state, action) {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists = _.map(card.checklists, (_checklist) => {
        if (_checklist.id === checklist.id) {
          return checklist;
        }
        return _checklist;
      });
    },
    deleteChecklist(state, action) {
      const { cardId, checklistId } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists = _.reject(card.checklists, { id: checklistId });
    },
    addCheckItem(state, action) {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];
      _.assign(card, {
        checklists: _.map(card.checklists, (checklist) => {
          if (checklist.id === checklistId) {
            _.assign(checklist, {
              checkItems: [...checklist.checkItems, checkItem]
            });
          }
          return checklist;
        })
      });
    },
    updateCheckItem(state, action) {
      const {
        cardId,
        checklistId,
        checkItem
      } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.map(checklist.checkItems, (_checkItem) => {
              if (_checkItem.id === checkItem.id) {
                return checkItem;
              }
              return _checkItem;
            })
          });
        }
        return checklist;
      });
    },
    deleteCheckItem(state, action) {
      const { cardId, checklistId, checkItemId } = action.payload;
      const card = state.cards.byId[cardId];
      card.checklists = _.map(card.checklists, (checklist) => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.reject(checklist.checkItems, { id: checkItemId })
          });
        }
        return checklist;
      });
    }
  }
});

export const { reducer } = slice;

export const getBoard = () => async (dispatch) => {
  const objUser = {
    apiName: "getMasterClientApp",
    params: {
      email: "info@adjudica.ai"
    }
  };
  const response = await postConexion("documents/bridgeArupoBridge", objUser);

  dispatch(slice.actions.getBoard(response));
};
// upload File
export const uploadFile = (file: any, documentId: string) => async () => {
  await postConexionFile(`arupoAws/uploadFilev2?document_id=${documentId}`, file);
};

// Upload template
export const uploadTemplateAws = (fileContent: any, documentId: string, templateId: string) => async () => {
  const objFile = {
    content: fileContent,
    document_id: documentId,
    template_id: templateId
  };
  await postConexion(`arupoAws/uploadMd`, objFile);
};

// saveGeneralSelectedTemp
export const saveGeneralSelectedTemp = (templateId: string, documentId: string, fullPath: string, type: string) => async (dispatch) => {
  const objSelectedTemp = {
    templateId: parseInt(templateId),
    type
  };
  await postConexion("documents/saveSelectedTemp", objSelectedTemp);
  dispatch(slice.actions.saveGeneralSelectedTemp({ documentId, fullPath }));
};

// workspaces
export const createWorkspace = (name: string, userEmail: string) => async (dispatch) => {
  const objUser = {
    workspace_name: name,
    email: userEmail
  };
  const { Message } = await postConexion("documents/createWork", objUser);
  const objResponse = {
    id: Message.workspace_id,
    name: Message.workspace_name,
    email: Message.email,
    documentsIds: []
  };
  dispatch(slice.actions.createWorkspace(objResponse));
};

export const deleteWorkspace = (workspaceId: string) => async (dispatch) => {
  const objWorkDelete = {
    workspace_id: parseInt(workspaceId)
  };
  await deleteConexion("documents/deleteWork", objWorkDelete);
  dispatch(slice.actions.deleteWorkspace({ workspaceId }));
};

export const updateWorkspace = (newName: string, update: any, elements: any) => async (dispatch) => {
  const objWorkUpdate = {
    workspaceId: parseInt(update.id),
    workspaceName: newName
  };
  await postConexion("documents/updateWork", objWorkUpdate);
  const objResponse = { ...update, name: newName };
  dispatch(slice.actions.updateWorkspace(objResponse));
};

// documents
export const createDocument = (workspaceId: string, name: string, userEmail: string) => async (dispatch) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const currentDate = `${mm}/${dd}/${yyyy} ${time}`;
  const objDoc = {
    workspace_id: parseInt(workspaceId),
    document_name: name,
    document_date: currentDate,
    document_creator: userEmail,
    document_toggle: false,
    document_status: "",
    allVariables: {}
  };
  const { Message } = await postConexion("documents/createDoc", objDoc);
  const { value } = Message;
  const objResponse = {
    workspaceId,
    id: value.document_id,
    name: value.document_name,
    date: value.document_date,
    creator: value.document_creator,
    togle: value.document_toggle,
    status: value.document_status,
    templatesIds: [],
    pagesIds: [],
    allVariables: {}
  };
  dispatch(slice.actions.createDocument(objResponse));
  return value.document_id;
};

export const deleteDocument = (documentId: string) => async (dispatch) => {
  const objDocDelete = {
    document_id: parseInt(documentId)
  };
  await deleteConexion("documents/deleteDocument", objDocDelete);
  dispatch(slice.actions.deleteDocument({ documentId }));
};

export const updateDocument = (update: any, funcion: string, status?: string) => async (dispatch) => {
  let objResponse = { ...update };
  let objResponseApi = {};
  const objUpdateDoc = {
    documentId: update.id,
    documentName: update.name,
    documentDate: update.date,
    documentCreator: update.creator,
    documentToggle: update.toggle,
    documentNotarized: update.notarized,
    documentStatus: update.status,
    workspaceId: update.workspaceId,
    allVariables: update.allVariables,
  };
  switch (funcion) {
  case 'title':
    objResponseApi = { ...objUpdateDoc, documentName: status };
    break;
  case 'toggle':
    objResponseApi = { ...objUpdateDoc, documentToggle: !update.toggle };
    break;
  case 'notarized':
    objResponseApi = { ...objUpdateDoc, documentNotarized: !update.notarized };
    break;
  case 'status':
    objResponseApi = { ...objUpdateDoc, documentStatus: status };
    break;
  default:
    objResponseApi = { ...objUpdateDoc };
    break;
  }
  await postConexion("documents/updateDoc", objResponseApi);
  switch (funcion) {
  case 'title':
    objResponse = { ...update, name: status };
    break;
  case 'toggle':
    objResponse = { ...update, toggle: !update.toggle };
    break;
  case 'notarized':
    objResponse = { ...update, notarized: !update.notarized };
    break;
  default:
    objResponse = { ...update };
    break;
  }
  dispatch(slice.actions.updateDocument(objResponse));
};

export const updateDocumentAllVariables = (update: any, action: string, valVariable?: string, elementsIds?: any) => async (dispatch) => {
  let objResponse = {};
  const newVars = { ...update.allVariables };
  if (action === "create") newVars[elementsIds[0]] = valVariable;
  else if (action === "delete") elementsIds.map((elementId) => delete newVars[elementId]);
  const objUpdateDoc = {
    documentId: update.id,
    documentName: update.name,
    documentDate: update.date,
    documentCreator: update.creator,
    documentToggle: update.toggle,
    documentNotarized: update.notarized,
    documentStatus: update.status,
    workspaceId: update.workspaceId,
    allVariables: { ...newVars }
  };
  await postConexion("documents/updateDoc", objUpdateDoc);
  objResponse = { ...update, allVariables: { ...newVars } };
  dispatch(slice.actions.updateDocument(objResponse));
};

// templates
export const createTemplate = (documentId: string, name: string, content: Array<string>) => async (dispatch) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const currentDate = `${mm}/${dd}/${yyyy} ${time}`;
  const objTemplate = {
    document_id: parseInt(documentId),
    name,
    date: currentDate,
    content
  };
  const { Message } = await postConexion("documents/createTemp", objTemplate);
  const { value } = Message;
  const objResponse = {
    documentId: value.document_id,
    id: value.template_id,
    name: value.description,
    date: currentDate,
    content
  };
  window.location.replace(`/app/users/editor/${value.document_id}_${value.template_id}`);
  dispatch(slice.actions.createTemplate(objResponse));
};

export const updateTemplate = (newContent: string, update: any) => async (dispatch) => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const currentDate = `${mm}/${dd}/${yyyy} ${time}`;
  const objUpTemplate = {
    documentId: update.documentId,
    templateId: update.id,
    name: update.name,
    date: currentDate,
    content: newContent
  };
  await postConexion("documents/updateTemp", objUpTemplate);
  const objResponse = { ...update, date: currentDate, content: newContent };
  dispatch(slice.actions.updateTemplate(objResponse));
};

export const deleteTemplate = (templateId: string) => async (dispatch) => {
  const objTemplateDelete = {
    template_id: parseInt(templateId)
  };
  await deleteConexion("documents/deleteTemplate", objTemplateDelete);
  dispatch(slice.actions.deleteTemplate({ templateId }));
};

export const deleteTemplateWord = (templateDocId: string, fullPath: string) => async (dispatch) => {
  const objTemplateDelete = {
    template_id: parseInt(templateDocId),
    docName: fullPath
  };
  await deleteConexion("documents/deleteTemplateDoc", objTemplateDelete);
  dispatch(slice.actions.deleteTemplateWord({ templateDocId }));
};

// pages
export const createPage = (documentId: string, name: string, position: number) => async (dispatch) => {
  const objPage = {
    document_id: parseInt(documentId),
    page_name: name,
    page_conditional: false,
    statement: { type: "", variable: "", condition: "" },
    position
  };
  const { Message } = await postConexion("documents/createPage", objPage);
  const { value } = Message;
  const objResponse = {
    documentId: value.document_id,
    id: value.page_id,
    name: value.page_name,
    conditional: false,
    statement: { type: "", variable: "", condition: "" },
    elementsIds: [],
    position
  };
  dispatch(slice.actions.createPage(objResponse));
};

export const updatePage = (update: any, funcion: string, varString?: string, varArray?: any) => async (dispatch) => {
  let objResponseApi = {};
  let objResponse = {};
  const objUpPage = {
    documentId: update.documentId,
    pageId: update.id,
    pageName: update.name,
    statement: { ...update.statement },
    position: update.position
  };
  switch (funcion) {
  case 'title':
    objResponseApi = { ...objUpPage, pageName: varString };
    break;
  case 'conditional':
    objResponseApi = { ...objUpPage, pageConditional: !update.conditional };
    break;
  case 'statement':
    objResponseApi = { ...objUpPage, statement: varArray };
    break;
  default:
    objResponseApi = { ...objUpPage };
    break;
  }
  await postConexion("documents/updatePage", objResponseApi);
  switch (funcion) {
  case 'title':
    objResponse = { ...update, name: varString };
    break;
  case 'conditional':
    objResponse = { ...update, conditional: !update.conditional };
    break;
  case 'statement':
    objResponse = { ...update, statement: varArray };
    break;
  default:
    objResponse = { ...update };
    break;
  }
  dispatch(slice.actions.updatePage(objResponse));
};

export const clearPage = (pageId: string) => async (dispatch) => {
  /* Call API */
  dispatch(slice.actions.clearPage({ pageId }));
};

export const deletePage = (pageId: string) => async (dispatch) => {
  const objPageDelete = {
    page_id: parseInt(pageId)
  };
  const resp = await deleteConexion("documents/deletePage", objPageDelete);
  dispatch(slice.actions.deletePage({ pageId }));
};

// elements
export const moveElement = (elementId: number, position: number, update?: any) => async (dispatch) => {
  const objMoveElement = {
    element_id: elementId,
    next_position: position,
    page_id: update.pageId,
  };
  await postConexion("documents/exchangeElement", objMoveElement);
  dispatch(slice.actions.moveElement({
    elementId,
    position
  }));
};

export const createElement = (pageId: string, name: string, position: number) => async (dispatch) => {
  const objElement = {
    page_id: parseInt(pageId),
    element_name: name,
    element_simbol: position,
    element_title: "Ingresa aquí tu pregunta",
    element_value: [],
    element_variable: [],
    element_expand: false,
    element_conditional: false,
    required: false,
    statement: { type: "", variable: "", condition: "" },
    position
  };
  const { Message } = await postConexion("documents/createElement", objElement);
  const { value } = Message;
  const objResponse = {
    pageId: value.page_id,
    id: value.element_id,
    name: value.element_name,
    simbol: value.element_simbol,
    value: [],
    variable: [],
    expand: value.element_expand,
    conditional: value.element_conditional,
    title: value.element_title,
    itemsIds: [],
    required: false,
    statement: { type: "", variable: "", condition: "" },
    position
  };
  dispatch(slice.actions.createElement(objResponse));
};

export const deleteElement = (pageId: string, elementId: string) => async (dispatch) => {
  const objElementDelete = {
    element_id: parseInt(elementId)
  };
  const resp = await deleteConexion("documents/deleteElement", objElementDelete);
  dispatch(slice.actions.deleteElement({ pageId, elementId }));
};

export const clearElement = (elementId: string) => async (dispatch) => {
  /* Call API */
  dispatch(slice.actions.clearElement({ elementId }));
};

export const updateElementAPI = (update: any, funcion: string, varString?: string, varArray?: any) => async (dispatch) => {
  let objResponse = {};
  let objResponseApi = {};
  const objUpElement = {
    pageId: update.pageId,
    elementId: update.id,
    elementName: update.name,
    elementSimbol: update.simbol,
    elementValue: update.value,
    elementVariable: update.variable,
    elementExpand: false,
    elementConditional: update.conditional,
    elementTitle: update.title,
    required: update.required,
    statement: { ...update.statement },
    position: update.position
  };
  switch (funcion) {
  case 'title':
    objResponseApi = { ...objUpElement, elementTitle: varString };
    break;
  case 'required':
    objResponseApi = { ...objUpElement, required: !update.required };
    break;
  case 'variable':
    objResponseApi = { ...objUpElement, elementVariable: varArray };
    break;
  case 'conditional':
    objResponseApi = { ...objUpElement, elementConditional: !update.conditional };
    break;
  case 'statement':
    objResponseApi = { ...objUpElement, statement: varArray };
    break;
  case 'type':
    objResponseApi = { ...objUpElement, elementName: varString };
    break;
  case 'value':
    objResponseApi = { ...objUpElement, elementValue: varArray };
    break;
  default:
    objResponseApi = { ...objUpElement };
    break;
  }
  await postConexion("documents/updateElement", objResponseApi);
  switch (funcion) {
  case 'title':
    objResponse = { ...update, title: varString };
    break;
  case 'required':
    objResponse = { ...update, required: !update.required };
    break;
  case 'variable':
    objResponse = { ...update, variable: varArray };
    break;
  case 'conditional':
    objResponse = { ...update, conditional: !update.conditional };
    break;
  case 'statement':
    objResponse = { ...update, statement: varArray };
    break;
  case 'type':
    objResponse = { ...update, name: varString };
    break;
  case 'value':
    objResponse = { ...update, value: varArray };
    break;
  default:
    objResponse = { ...update };
    break;
  }
  dispatch(slice.actions.updateElement(objResponse));
};

export const updateElement = (update: any, funcion: string, name?: string) => async (dispatch) => {
  let objResponse = { ...update };
  switch (funcion) {
  case 'expand':
    objResponse = { ...update, expand: !update.expand, };
    break;
  }
  dispatch(slice.actions.updateElement(objResponse));
};

// items
export const moveItem = (itemId: number, position: number, update?: any) => async (dispatch) => {
  const objMoveItem = {
    item_id: itemId,
    next_position: position,
    element_id: update.elementId
  };
  await postConexion("documents/exchangeItem", objMoveItem);
  dispatch(slice.actions.moveItem({
    itemId,
    position
  }));
};

export const createItem = (elementId: string, name: string, position: number) => async (dispatch) => {
  const objItem = {
    element_id: elementId,
    item_name: name,
    item_simbol: position,
    item_title: "Ingresa aquí tu pregunta",
    item_value: [],
    item_variable: [],
    item_expand: false,
    item_conditional: false,
    required: false,
    statement: { type: "", variable: "", condition: "" },
    position
  };
  const { Message } = await postConexion("documents/createItem", objItem);
  const { value } = Message;
  const objResponse = {
    elementId: value.element_id,
    id: value.item_id,
    name: value.item_name,
    simbol: value.item_simbol,
    title: value.item_title,
    value: [],
    variable: [],
    expand: value.item_expand,
    conditional: value.item_conditional,
    required: false,
    statement: { type: "", variable: "", condition: "" },
    position
  };
  dispatch(slice.actions.createItem(objResponse));
};

export const deleteItem = (itemId: string) => async (dispatch) => {
  const objItemDelete = {
    item_id: parseInt(itemId)
  };
  const resp = await deleteConexion("documents/deleteItem", objItemDelete);
  dispatch(slice.actions.deleteItem({ itemId }));
};

export const updateItemAPI = (update: any, funcion: string, varString?: string, varArray?: any) => async (dispatch) => {
  let objResponse = {};
  let objResponseApi = {};
  const objUpItem = {
    elementId: update.elementId,
    itemId: update.id,
    itemName: update.name,
    itemSimbol: update.simbol,
    itemValue: update.value,
    itemVariable: update.variable,
    itemExpand: false,
    itemConditional: update.conditional,
    itemTitle: update.title,
    required: update.required,
    statement: { ...update.statement },
    position: update.position
  };
  switch (funcion) {
  case 'title':
    objResponseApi = { ...objUpItem, itemTitle: varString };
    break;
  case 'required':
    objResponseApi = { ...objUpItem, required: !update.required };
    break;
  case 'variable':
    objResponseApi = { ...objUpItem, itemVariable: varArray };
    break;
  case 'conditional':
    objResponseApi = { ...objUpItem, itemConditional: !update.conditional };
    break;
  case 'statement':
    objResponseApi = { ...objUpItem, statement: varArray };
    break;
  case 'type':
    objResponseApi = { ...objUpItem, itemName: varString };
    break;
  case 'value':
    objResponseApi = { ...objUpItem, itemValue: varString };
    break;
  case 'deleteVal':
    objResponseApi = { ...objUpItem, itemValue: varString };
    break;
  default:
    objResponseApi = { ...objUpItem };
    break;
  }
  await postConexion("documents/updateItem", objResponseApi);
  switch (funcion) {
  case 'title':
    objResponse = { ...update, title: varString };
    break;
  case 'required':
    objResponse = { ...update, required: !update.required };
    break;
  case 'variable':
    objResponse = { ...update, variable: varArray };
    break;
  case 'conditional':
    objResponse = { ...update, conditional: !update.conditional };
    break;
  case 'statement':
    objResponse = { ...update, statement: varArray };
    break;
  case 'type':
    objResponse = { ...update, name: varString };
    break;
  case 'value':
    objResponse = { ...update, value: [...update.value, varString] };
    break;
  case 'deleteVal':
    objResponse = { ...update, value: varArray };
    break;
  default:
    objResponse = { ...update };
    break;
  }
  dispatch(slice.actions.updateItem(objResponse));
};

export const updateItem = (update: any, funcion: string) => async (dispatch) => {
  let objResponse = { ...update };
  switch (funcion) {
  case 'expand':
    objResponse = { ...update, expand: !update.expand, };
    break;
  }
  dispatch(slice.actions.updateItem(objResponse));
};

// Send Questions
export const sendQuestions = (objContext: any, templatePath: string, notarized: boolean) => async () => {
  let pdf = new Boolean();
  if (notarized) pdf = true;
  else if (notarized == false || notarized == null) pdf = false;
  const objRespQuestions = {
    apiName: "generateTemp",
    params: { template_path: templatePath,
      context: { ...objContext },
      convert_to_pdf: true
    }
  };
  const resp = await postConexion("documents/bridgeArupoTableUncat", objRespQuestions);
  resp.pdf = pdf;
  return resp;
};

// List
export const updateList = (listId: string, update: any) => async (dispatch) => {
  listId = "5e849c39325dc5ef58e5a5db";
  const response = await axios.post('/api/kanban/list/update', {
    listId,
    update
  });
  dispatch(slice.actions.updateList(response.data));
};

export const clearList = (listId: string) => async (dispatch) => {
  await axios.post('/api/kanban/lists/clear', {
    listId
  });
  dispatch(slice.actions.clearList({ listId }));
};

export const deleteList = (listId: string) => async (dispatch) => {
  await axios.post('/api/kanban/lists/remove', {
    listId
  });
  dispatch(slice.actions.deleteList({ listId }));
};

// card
export const createCard = (listId: string, name: string) => async (dispatch) => {
  const response = await axios.post('/api/kanban/cards/new', {
    listId,
    name
  });
  dispatch(slice.actions.createCard(response.data));
};

export const updateCard = (cardId: string, update: any) => async (dispatch) => {
  const response = await axios.post('/api/kanban/cards/update', {
    cardId,
    update
  });
  dispatch(slice.actions.updateCard(response.data));
};

export const moveCard = (cardId: string, position: number, listId?: string) => async (dispatch) => {
  await axios.post('/api/kanban/cards/move', {
    cardId,
    position,
    listId
  });
  dispatch(slice.actions.moveCard({
    cardId,
    position,
    listId
  }));
};

export const deleteCard = (cardId: string) => async (dispatch) => {
  await axios.post('/api/kanban/cards/remove', {
    cardId
  });
  dispatch(slice.actions.deleteCard({ cardId }));
};

export const addComment = (cardId: string, message: string) => async (dispatch) => {
  const response = await axios.post<{ comment: Comment; }>('/api/kanban/comments/new', {
    cardId,
    message
  });
  dispatch(slice.actions.addComment(response.data));
};

export const addChecklist = (cardId: string, name: string) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checklists/new', {
    cardId,
    name
  });
  const { checklist } = response.data;
  dispatch(slice.actions.addChecklist({
    cardId,
    checklist
  }));
};

export const updateChecklist = (cardId: string, checklistId: string, update: any) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checklists/update', {
    cardId,
    checklistId,
    update
  });
  const { checklist } = response.data;
  dispatch(slice.actions.updateChecklist({
    cardId,
    checklist
  }));
};

export const deleteChecklist = (cardId: string, checklistId: string) => async (dispatch) => {
  await axios.post('/api/kanban/checklists/remove', {
    cardId,
    checklistId
  });
  dispatch(slice.actions.deleteChecklist({
    cardId,
    checklistId
  }));
};

export const addCheckItem = (cardId: string, checklistId: string, name: string) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checkitems/new', {
    cardId,
    checklistId,
    name
  });
  const { checkItem } = response.data;
  dispatch(slice.actions.addCheckItem({
    cardId,
    checklistId,
    checkItem
  }));
};

export const updateCheckItem = (cardId: string, checklistId: string, checkItemId: string, update: any) => async (dispatch) => {
  const response = await axios.post('/api/kanban/checkitems/update', {
    cardId,
    checklistId,
    checkItemId,
    update
  });
  const { checkItem } = response.data;
  dispatch(slice.actions.updateCheckItem({
    cardId,
    checklistId,
    checkItem
  }));
};

export const deleteCheckItem = (cardId: string, checklistId: string, checkItemId: string) => async (dispatch) => {
  await axios.post('/api/kanban/checkitems/remove', {
    cardId,
    checklistId,
    checkItemId
  });
  dispatch(slice.actions.deleteCheckItem({
    cardId,
    checklistId,
    checkItemId
  }));
};

export default slice;
