import React, { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  image: {
    width: '30%',
    maxHeight: '100%'
  }
}));

const LoadingLogo: FC = () => {
  const classes = useStyles();
  const settings = window.localStorage.getItem('settings') || 'LIGHT';

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box
        alignItems="center"
        textAlign="center"
        width={400}
      >
        <img
          alt="Arupo hub Loading"
          title="Arupo hub cargando"
          className={classes.image}
          src={settings.includes("LIGHT") ? "/static/images/gif/Legaltech_loading.gif" : "/static/images/gif/ArupoLogo_white.gif"}
        />
      </Box>
    </div>
  );
};

export default LoadingLogo;
