import { makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
    fontFamily: 'Poppins',
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
  },
  navItem: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: "#FFFFFF",
    "&:hover": {
      color: "#4CDA96",
    }
  },
  navItemActivated: {
    cursor: 'pointer',
    color: "#4CDA96",
    textDecoration: 'none',
    "&:hover": {
      color: "#4CDA96",
    }
  },
  drawGeneral: {
    background: "white",
    "& a": {
      color: "#59428E !important",
      textDecoration: 'none',
      fontSize: 20,
      fontFamily: 'Poppins',
      fontWeight: 600,
    }
  },
  sideBarActivatedMenu: {
    color: "#4CDA96",
  },
  sideBarNoActivatedMenu: {
    color: "black",
  },
  backgroundHero: {
    height: "100%",
    background: "linear-gradient(89.88deg, #3E2675 1.45%, #7F41A3 48.07%, #F2AFBA 98.39%)",
  },
  boxGeneralNavBar: {
    paddingTop: 24,
    paddingLeft: 60,
    paddingRight: 45,
    color: "#FFFFFF",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
  },
  boxMainContentHero: {
    padding: '7vh 7vh 7vh 8vh',
    color: "#FFFFFF",
    fontFamily: 'Poppins',
    fontStyle: "normal",
  },
  titleHero: {
    paddingBottom: 24,
    color: "#FFFFFF",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 50,
    [theme.breakpoints.down('md')]: {
      fontSize: 25,
    }
  },
  subTitleHero: {
    paddingBottom: 49,
    paddingRight: 125,
    color: "#FFFFFF",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      fontSize: 18,
    }
  },
  exploraButton: {
    background: "linear-gradient(90deg, #4EDA93 2.75%, #41DBAC 52.1%, #35DBC4 82.11%)",
    color: 'white',
    borderRadius: '20px',
    borderColor: '#4dda96',
    border: '1px solid #4dda96',
    minWidth: '211px',
    '@media (max-width: 1190px)': {
      marginTop: '20px'
    }
  },
  comoButton: {
    backgroundColor: 'transparent',
    color: 'white',
    borderRadius: '20px',
    borderColor: 'white',
    border: '1px solid white',
    minWidth: '215px',
    "&:hover": {
      background: "linear-gradient(89deg, rgba(251,186,131,1) 1.45%, rgba(255,165,205,1) 48.07%)",
      color: '#FFFFFF',
      cursor: 'pointer'
    },
    '@media (max-width: 1190px)': {
      marginTop: '20px'
    }
  },
  loginButtonResponsive: {
    width: '100%',
    height: 60,
    backgroundColor: '#59428E',
    color: 'white',
    borderRadius: '30px',
    borderColor: 'white',
    border: '1px solid white',
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    "&:hover": {
      background: "transparent",
      color: 'black',
      cursor: 'pointer'
    }
  },
  loginButton: {
    width: '100%',
    height: 60,
    backgroundColor: 'transparent',
    color: 'white',
    borderRadius: '30px',
    borderColor: 'white',
    border: '1px solid white',
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    "&:hover": {
      background: "transparent",
      color: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  creaButton: {
    width: '100%',
    height: 60,
    background: "linear-gradient(90deg, #4EDA93 2.75%, #41DBAC 52.1%, #35DBC4 82.11%)",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    borderRadius: '24px',
    borderColor: 'transparent',
    textAlign: 'center',
    cursor: 'pointer',
    filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25))",
    "&:hover": {
      background: "linear-gradient(90deg, #4EDA93 2.75%, #41DBAC 52.1%, #35DBC4 82.11%)",
      color: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  creaButtonResponsive: {
    height: 60,
    background: "linear-gradient(90deg, #4EDA93 2.75%, #41DBAC 52.1%, #35DBC4 82.11%)",
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    borderRadius: '24px',
    borderColor: 'transparent',
    textAlign: 'center',
    cursor: 'pointer',
    filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25))",
    margin: '0px 15px',
    "&:hover": {
      background: "linear-gradient(90deg, #4EDA93 2.75%, #41DBAC 52.1%, #35DBC4 82.11%)",
      color: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  titleDesc: {
    paddingBottom: 24,
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 50,
    '@media (max-width: 960px)': {
      textAlign: 'center',
      paddingTop: '40px'
    }
  },
  subTitleDesc: {
    paddingBottom: 25,
    paddingRight: 125,
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 24,
    color: '#34DBC8',
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
    '@media (max-width: 960px)': {
      textAlign: 'center'
    }
  },
  boxMainContentDesc: {
    paddingTop: 97,
    paddingLeft: 60,
    paddingRight: 0,
    paddingBottom: 50,
    fontFamily: 'Poppins',
    fontStyle: "normal",
    backgroundColor: '#F7F8FB',
    width: '100wh',
    [theme.breakpoints.down('md')]: {
      paddingRight: 55,
    }
  },
  boxMainContentHow: {
    paddingTop: 27,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 25,
    fontFamily: 'Poppins',
    fontStyle: "normal",
    backgroundColor: '#F7F8FB',
    width: '100wh'
  },

  btnPlay: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100wh',
    position: 'absolute',
    left: "48%",
    top: "138%",
    zIndex: 1,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      left: "0",
      top: "0",
    }
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  /* styles  DescriptionDev */
  contentDescription: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  btnInit: {
    margin: '0px 0px 12px 0px',
    padding: '18px 20px',
    height: 63,
    background: '#FFFFFF',
    border: "1px solid #CBCCCD",
    borderRadius: 24,
    textTransform: 'initial',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 18,
    color: '#48465B',
    "&:hover": {
      background: "linear-gradient(89deg, rgba(251,186,131,1) 1.45%, rgba(255,165,205,1) 48.07%)",
      color: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  btnInitActive: {
    margin: '0px 0px 12px 0px',
    padding: '18px 20px',
    height: 63,
    background: '#FFFFFF',
    filter: "drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25))",
    borderRadius: 24,
    textTransform: 'initial',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 18,
    color: '#FFB6B2',
    "&:hover": {
      background: "linear-gradient(89deg, rgba(251,186,131,1) 1.45%, rgba(255,165,205,1) 48.07%)",
      color: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  labelBtn: {
    width: '100%',
    display: 'inherit',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxContent: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    color: "#48465B",
    margin: "16px 16px 20px 16px",
    display: 'inherit',
    alignItems: "center",
    justifyContent: 'center',
    '@media (max-width: 960px)': {
      textAlign: 'center'
    }
  },
  comoTxt: {
    paddingTop: '20px',
    '@media (max-width: 1280px)': {
      paddingBottom: '25px'
    }
  },
  boxTargetAdj: {
    width: "100%",
    minWidth: 290,
    height: 461,
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    filter: "drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.25))",
    marginTop: 90,
    '@media (max-width: 1280px)': {
      marginTop: '45px'
    }
  },
  gridSomos: {
    '@media (min-width: 960px) and (max-width: 1280px)': {
      marginLeft: '-25px'
    }
  },
  boxImage: {
    maxWidth: 372,
    maxHeight: 109,
    [theme.breakpoints.down('md')]: {
      width: '80%',
    }
  },
  boxTitle: {
    marginTop: 76,
    marginBottom: 18,
    color: "#9F7BCA",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 34,
    textAlign: 'center',
  },
  boxHashtag: {
    color: "#48465B",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 24,
    '@media (max-width: 470px)': {
      fontSize: 18
    }
  },
  divVideoAdj: {
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    background: '#1D263B',
  },
  divVideoHero: {
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  videoLanding: {
    minHeight: '300px',
    '@media (max-width: 960px)': {
      marginTop: '60px',
    }
  },
  diplayLineBreak: {
    display: 'none',
    '@media (max-width: 960px)': {
      color: 'transparent',
      display: 'block'
    }
  },
  exitDialog: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'end',
    alignContent: 'flex-end',
    padding: '10px',
    cursor: 'pointer',
    background: '#1D263B',
    color: "#FFFFFF"
  },
  /* styles  DescriptionDev */

  /* styles landing plans */
  boxMainContentPlans: {
    paddingTop: 67,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 70,
    fontFamily: 'Poppins',
    fontStyle: "normal",
    backgroundColor: '#F7F8FB',
    width: '100wh',
    height: '750px',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    }
  },
  titlePlan: {
    textAlign: 'center',
    color: "#48465B",
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 44,
  },
  subtitlePlan: {
    textAlign: 'center',
    color: "#48465B",
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 34,
  },
  paperPlan: {
    padding: "42px 48px 42px 48px",
    textAlign: 'center',
    backgroundColor: "#FFFFFF",
    color: "#48465B",
    borderRadius: 16,
    height: '60vh'
  },
  gridCenter: {
    display: 'flex',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center'
  },
  priceGeneral: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 38,
    color: "#48465B",
  },
  /* styles landing plans */

  /* styles como funciona */
  boxMainCard: {
    backgroundColor: 'red',
    width: '100%',
  },
  flipCard: {
    backgroundColor: 'transparent',
    width: '56vh',
    height: '52vh',
    perspective: '1000px',
    '@media (max-width: 1300px)': {
      width: '46vh',
      height: '43vh'
    },
    '@media (max-width: 1095px)': {
      width: '40vh',
      height: '37vh'
    },
    '@media (max-width: 740px)': {
      width: '36vh',
      height: '34vh'
    },
    '@media (max-width: 600px)': {
      width: '56vh',
      height: '52vh'
    }
  },

  flipCardInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    "&:hover": {
      transform: 'rotateY(180deg)',
    }
  },
  flipCardFront: {
    backgroundImage: `url(/static/images/adjudica_logos/fondoCard.svg)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: 'cover',
    position: 'absolute',
    width: '100%',
    height: '100%',
    // -webkit-backface-visibility: 'hidden',
    backfaceVisibility: 'hidden',
    backgroundColor: '#59428E',
    color: '#ffffff',
  },
  flipCardBack: {
    padding: "56px 27px 56px 27px",
    position: 'absolute',
    width: '100%',
    height: '100%',
    // -webkit-backface-visibility: 'hidden',
    backfaceVisibility: 'hidden',
    backgroundColor: '#59428E',
    color: 'white',
    transform: 'rotateY(180deg)',
  },
  flipCardImage: {
    width: '300px',
    height: '300px',
  },
  contentFrontCard: {
    margin: "86px 25px 173px 25px",
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
    color: '#FFFFFF',
  },
  nameContrato: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
    color: '#FFFFFF',
    marginBottom: 44,
  },
  descContrato: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    color: '#FFFFFF',
    marginBottom: 44,
  },
  titleSomos: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'left',
    color: '#9F7BCA',
  },
  subTitleSomos1: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 44,
    textAlign: 'left',
    color: '#48465B',
  },
  subTitleSomos2: {
    // fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 44,
    textAlign: 'left',
    color: '#47DAA0',
  },
  subTitleSomos3: {
    fontFamily: 'Work Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 24,
    textAlign: 'left',
    color: '#48465B',
  },
  drawer: {
    width: 250
  },
  visible: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      // display: 'block',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  menuNoVisible: {
    // display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  footerLogin: {
    position: "absolute",
    bottom: 0,
    width: "67%",
    textAlign: "center",
    '@media (max-width: 960px)': {
      width: '84%'
    }
  },
  boxIconX: {
    display: 'flex',
    justifyContent: 'end'
  },
  containerCard: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default useStyles;
